export default { 
    Hi: "Hi",
    we: "Welcome",
    labName:"Lab Technician",
    nurseName:"Nurse",
    noTaskFound:"No Task Found",
    tskDesc:"Task Description",
    testDesc:"Test Description",
    taskSelected: "Tasks selected",
    testSelected: "Tests selected",
    PkgSelected: "Packages selected",
    viewTaskDetails: "View task details",
    viewTestDetails: "View test details",
    viewPkgDetails: "View package details",
    selectTask: "Select Task",
    selectTest: "Select Test",
    ExtraTest: "Extra Tests",
    TestSelectpkg: "Test Selected in this Package",
    selectedTask: "Selected Task",
    selectedTest: "Selected Test",
    selectedPackage: "Selected Package",
    textNote: "Text note",
    location: "Location",
    writereason:"write reason",
    voiceNote: "Voice note",
    apnInfo : "Appointment Information",
    apnId: "Appointment ID",
    instructions: "Instructions",
    patientNtAvlbleUnableToColctSampl: "Patient not available, unable to collect sample",
    patientNtAvlbleUnableToCompltTask: "Patient not available, unable to complete task",
    slideTostartApnt: "Slide to Start Appointment",
    slideWnRchdDest : "Slide when reached destination",
    slideAftrCompltngTask : "Slide after completing task",
    slideAftrCollctSampl: "Slide after collecting Sample",
    startApt:"Start Appointment",
    reachedDestination:"Reached destination",
    taskCompleted:"Task Completed",
    sampleCollected:"Sample Collected",
    completeApt:"Complete appointment",
    provider_login:"Provider login",
    verify:"Verify",
    saveApnt: "Save Appointment"
}