/**
 * @description Auth Store Module
 * @author Mahin Anas
 */
import i18n from '../../i18n'
import http from './../../util/axios';
export default {
    namespaced: true,
    state: {
        locale: i18n.global.locale,
        token: '',

    },
    mutations: {
        SET_LOCALE: (state, locale) => {
            state.locale = locale
            localStorage.setItem('lang', locale)
        },
        SET_TOKEN: (state, payload) => {
            state.token = payload
            localStorage.setItem('token', payload)
        },

    },
    actions: {
        CHANGE_LANGUAGE({ commit }, lang) {
            if (lang) {
                i18n.global.locale = lang
            } else {
                i18n.global.locale = ((i18n.global.locale == 'en') ? 'ar' : 'en')
            }
            commit('SET_LOCALE', i18n.global.locale)
            return i18n.global.locale
        },
        //Verifying Auth Code and get token 
        DO_VERIFY({ commit }, payload) {
            let authReq = { "authcode": payload }
            return http.post('/auth', authReq)
                .then(response => {
                    const { status, data, message, desc } = response
                    if (data) {
                        commit('SET_TOKEN', data.token)
                        return { status: status, data: data }
                    } else {
                        console.log(commit);
                        return { status: status, message: desc || message }
                    }
                })
        },

    },
    getters: {
        getToken: state => { return state.token },
    },
}
