<template>
  <div id="app" class="sa-container">
    <router-view />
    <div v-if="alertMessage && alertMessage.msg" class="sa-alert sa-alert-fixed" :class="alertMessage.class">
      <span :class="alertMessage.iconClass"></span> {{alertMessage.msg}} <i class="icon-close sa-a-close" v-on:click="resetAlertMessage()"></i>
    </div>
    <div v-if="loading" class="sa-loader-bg">
      <img src="/img/loader.svg" alt="loader" class="sa-loader" />
    </div>
    <chat-notification-pop v-if="showNotification"/>
  </div>
</template>
<script>
import ChatNotificationPopVue from './components/chat/ChatNotificationPop.vue'
export default {
  components:{
    "chat-notification-pop": ChatNotificationPopVue,
  },
  name: 'App',
  data(){
    return{
    }
  },
  watch: {
    alertMessage: {
      deep: true,
      immediate: true,
      handler() {
        if (this.alertMessage && this.alertMessage.msg) {
          setTimeout(() => {
            this.$store.dispatch('common/SET_ALERTMESSAGE', { 'iconClass': '', 'msg': '', class: '' })
          }, 5000)
        }
      }
    }
  },
  computed:{
    alertMessage: function () {
      return this.$store.getters['common/getAlertMessage']
    },
    loading: function(){
      return this.$store.getters['common/getLoading']
    },
    showNotification() {
      return this.$store.getters["common/getNotificationStatus"];
    },
  },
  methods: {
    resetAlertMessage() {
      this.$store.dispatch('common/SET_ALERTMESSAGE', {  'iconClass': '','msg': '', class: '' })
    },    
    viewNotification() {
      this.$store.dispatch("common/SHOW_NOTIFICATION", false);
      this.$store.dispatch("common/ON_NOTIFICATION_CLICK");
    },
    cancelNotification() {
      this.$store.dispatch("common/SHOW_NOTIFICATION", false);
      this.$store.dispatch("common/SET_NOTIFICATION_PAYLOAD", null);
    },
  }
}
</script>