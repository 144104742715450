import { createStore } from "vuex";
import common from './modules/common'
import auth from './modules/auth'
import appointments from './modules/appointments'
const store = createStore({
   modules: {
      common: common,
      auth: auth,
      appointments: appointments
   },
   state: {
      name: "Vue"
   }
});
window.store = store

export default store;