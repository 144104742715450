import { createWebHistory, createRouter } from "vue-router";
import i18n from '../i18n'
import store from '../store'

const AppointmentDetails = () => import("@/views/appointment/AppointmentDetails.vue")
const Auth = () => import("@/views/auth/index.vue")
const ChatView = () => import("@/views/chat/ChatView.vue")

const routes = [
  { path: "/", redirect: "/login", },
  { path: "/login", name: "login", component: Auth, },
  { path: "/appointment-details", name: 'appointment-details', component: AppointmentDetails, },
  { path: "/chat-view", name: 'chat-view', component: ChatView, },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let lang = localStorage.getItem('lang') || i18n.global.locale
  await store.dispatch('common/CHANGE_LANGUAGE', lang)
  next()
})

export default router;