import { createApp } from 'vue'
import i18n from "./i18n"
import { useI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from "./store"
import '../public/css/custom-en.css'

const VueApp = createApp(App, {
    setup() {
      const { t, locale } = useI18n()
      return { t, locale }
    }
  })
  VueApp.config.devtools = true
VueApp.use(store)
.use(i18n)
.use(router)
.mount('#app')
