/**
 * @description Appoinments Store Module
 * @author Mahin Anas
 */
/* eslint-disable */ 
import http from '../../util/axios';
import {communicationHub,socketHub} from '../../../config/default.json'
export default {
    namespaced: true,
    state: {
        activeAppointments: null,

    },
    mutations: {
        SET_ACTIVE_APPOINTMENTS: (state, payload) => {
            state.activeAppointments = payload
        },
    },
    actions: {
        GET_ACTIVE_APPOINTMENTS({ commit }) {
            return http.get('/apntinfo').then(response => {
                const { data } = response
                if (data.status === 1000) {
                    commit('SET_ACTIVE_APPOINTMENTS', response.data)
                    return { status: data.status, data: data.data }
                } else {
                    commit('SET_ACTIVE_APPOINTMENTS', null)
                    return { status: data.status }
                }
            })
        },
        // eslint-disable-next-line no-unused-vars
        DO_UPDATE_STATUS({ commit }, payload) {
            return http.post('/updatestatus', payload).then(response => {
                let { data } = response
                return { data: data }
            })
        },
        // eslint-disable-next-line no-unused-vars
        INIT_CHAT({ commit }, payload) {
            return http.post('/chat', payload).then(response => {
                let { data } = response
                return { data: data }
            })
        },
        // //get Twilio token
        GET_TWILIO_TOKEN({ commit }, payload){
            return http.post(`${communicationHub}/video/create-room`, payload).then(response => {
                let { data } = response
                return { data: data }
            })
        },
         // //get Twilio token
         GET_VIDEOCALL_TOKEN({ commit }, payload){
            return http.post(`${communicationHub}/videocall/get-access-token`, payload).then(response => {
                let { data } = response
                return { data: data }
            })
        },
        // video call
        SOCKET_VIDEO_CALL({ commit }, payload){
            return http.post(`${socketHub}/socket/video_call_v1`, payload).then(response => {
                let { data } = response
                return { data: data }
            })
        },
        //missed call
        MISSED_CALL({ commit }, payload){
            return http.post(`${socketHub}/socket/reject_video_call_v1`, payload).then(response => {
                let { data } = response
                return { data: data }
            })
        }
    },
    getters: {
        getActiveAppointments: state => { return state.activeAppointments },
    },
}
