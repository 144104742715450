/**
 * @description Axios HTTP Handler Utility
 * @author Fahid Mohammad
 */
// TODO: Need to handle all types of request in case of post
//       Need to clear unwanted consolses once the final development is over
//       Code refactor is needed, need to find a better solution to handle request and response with a rejection
import axios from 'axios'
import store from './../store'
import config  from '../../config/default.json'


let http = axios.create({
  baseURL: config.operationHub,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': localStorage.getItem("token") || "",
    'platform': 'mac'
  },
  withCredentials: false
})

http.interceptors.request.use((req) => {
  let token = store.getters['auth/getToken'] || null
  if (token) {
    req.headers['Authorization'] = token;
  }
  return req;
}, (error) => {
  return Promise.reject(error)
})

export default http
