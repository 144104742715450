/**
 * @description Common Store Module
 * @author Fahid Mohammad
 */
/* eslint-disable */
import i18n from '../../i18n'
import router from '../../router'

export default {
    namespaced: true,
    state:{
        loading: false,
        locale:i18n.global.locale,
        text:null,
        location:{
            address: 'Riyadh Saudi Arabia',
            latitude: "24.7135517",
            longitude: "46.6752957"
        },
        permissions : {
            location: false,
            notification: false,
            camera: false,
            audio: false
        },
		alertMessage: {
			msg: '',
            class: '',
            iconClass: ''
		},
        showNotification: false,
        notificationPayload: null,
    },
    mutations:{
        SET_LOADING:(state, isLoading) => {
            state.loading = isLoading
        },
        SET_LOCALE: (state, locale) => {
            state.locale = locale
            localStorage.setItem('lang', locale)
        },
        SET_TEXT: (state, payload) => {
            state.text = payload
        },
        SET_LOCATION: (state, payload) => {
            state.location = payload
        },
        SET_ALERTMESSAGE: (state, payload) => {
            state.alertMessage = payload
        },
        SET_NOTIFICATION_PAYLOAD:(state, payload) => {
            state.notificationPayload = payload
        },
    },
    actions: {
        CHANGE_LANGUAGE({ commit }, lang) {
            if(lang){
                i18n.global.locale = lang
            }else{
                i18n.global.locale = ((i18n.global.locale=='en')? 'ar':'en')
            }
            commit('SET_LOCALE', i18n.global.locale )
            return i18n.global.locale
        },
        CHANGE_TEXT({ commit }, payload) {
            commit('SET_TEXT', payload )
        },
        async GET_PLACE_NAME({dispatch},{latitude, longitude}){
            
            console.log('geocode here');
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: { lat: parseFloat(latitude), lng: parseFloat(longitude)} }, (results, status) => {
                console.log('result : ', results)
                if (status === "OK") {
                  if (results[0]) {
                    let location = {
                        latitude:latitude.toString(),
                        longitude:longitude.toString(),
                        address:results[0].formatted_address
                    }
                    // commit('SET_LOCATION', location)
                    dispatch('SET_LOCATION', location)
                    // dispatch('SET_PERMISSION', {key:'location', value: true})
                  } else {
                    console.log("No results found");
                  }
                } else {
                  console.log("Geocoder failed due to: " + status);
                }
              });
              return;
        },
        SET_LOCATION( { commit } , payload) {
            commit('SET_LOCATION', payload)
        },
        SET_ALERTMESSAGE( { commit } , payload) {
            commit('SET_ALERTMESSAGE', payload);
        },
        SET_LOADING({commit}, isLoading){
            commit('SET_LOADING', isLoading)
        },
        SET_NOTIFICATION_PAYLOAD({ commit }, payload) {
            commit('SET_NOTIFICATION_PAYLOAD', payload)
        },
        ON_NOTIFICATION_CLICK({ getters }) {
            const {  type } = getters.getNotificationPayload.data
            switch (type) {
                case 'chat':                  
                    router.push({
                      name: 'chat-view',
                    });                                           
                    break;                
                default:
                    break;
            }
        },
    },
    getters:{
        getLocale: state => {
            return state.locale
        },
        getText: state=>{
            return state.text
        },
        getLocationValues: state => { return state.location },
        isLocationEnabled: state => state.permissions.location,
        getAlertMessage: state => state.alertMessage,
        getLoading: state => state.loading,
        getNotificationPayload: state => state.notificationPayload,
    },
}
