<template>
  <div class="sa-push-notific">
    <i class="icon-logo-symbol"></i>
    <h6>{{ notificationPayload.notification.title }}</h6>
    <p class="sa-mt0">{{ notificationPayload.notification.body }}</p>
    <button
      @click="cancelNotification"
      class="sa-btn sa-btn-outline-primary sa-btn-small"
    >
      {{ $t("cancel") }}
    </button>
    <button
      v-if="!inCall"
      @click="viewNotification"
      class="sa-btn sa-btn-primary sa-btn-small sa-ml10"
    >
      {{ $t("view") }}
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    notificationPayload() {
      return this.$store.getters["common/getNotificationPayload"];
    },
  }
};
</script>